<template>
  <div class="content_body" v-loading="loading">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="支出管理" name="0">
        <div class="nav_header">
          <el-row>
            <el-col :span="22">
              <el-form :inline="true" size="small" @keyup.enter.native="handleSearch">
                <el-form-item label="付款人">
                  <el-input @clear="handleSearch" v-model="payName" placeholder="请输入付款人" clearable style="width:150px;"></el-input>
                </el-form-item>
                <!-- 选择门店 -->
                <el-form-item label="门店" v-if="entityList.length>1">
                  <el-select style="width:150px;" clearable filterable v-model="EmpStores" placeholder="请选择门店" @change="handleSearch" @clear="handleSearch">
                    <el-option :label="item.Name" :value="item.ID" v-for="(item, index) in entityList" :key="index"></el-option>
                  </el-select>
                </el-form-item>
                <!-- 支出类型 -->
                <el-form-item label="支出类型">
                  <el-select style="width:150px;" clearable v-model="BillType" placeholder="请选择类别" @change="handleSearch" @clear="handleSearch">
                    <el-option :label="item.Name" :value="item.ID" v-for="(item, index) in BillTypeList" :key="index"></el-option>
                  </el-select>
                  <!-- 筛选时间 -->
                </el-form-item>
                <el-form-item label="筛选时间">
                  <el-date-picker v-model="dateTime" range-separator="至" type="daterange" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" size="small" @click="handleSearch" v-prevent-click>搜索</el-button>
                </el-form-item>
              </el-form>
            </el-col>
            <el-col :span="2" class="text_right">
              <el-button type="primary" size="small" @click="showAddDialog" v-prevent-click>新增支出</el-button>
            </el-col>
          </el-row>
        </div>
        <div class="martp_10">
          <el-table :data="productTableData" size="small" tooltip-effect="light">
            <el-table-column prop="CategoryName" label="支出类型"></el-table-column>
            <el-table-column label="付款方式/金额">
              <template slot-scope="scope">
                <div>
                  <div>{{scope.row.PaymentsName}}</div>
                  <div>{{scope.row.Amount}}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="付款人/时间">
              <template slot-scope="scope">
                <div>
                  <div>{{scope.row.Payer}}</div>
                  <div>{{scope.row.SpendingDate}}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="记录人/时间">
              <template slot-scope="scope">
                <div>
                  <div>{{scope.row.EmpName}}</div>
                  <div>{{scope.row.CreatedOn}}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="Content" label="支出内容" show-overflow-tooltip tooltip-effect="light">
            </el-table-column>
            <el-table-column label="操作" width="145px">
              <template slot-scope="scope">
                <el-button type="primary" size="small" @click="clickEditBtn(scope.row)" v-prevent-click>编辑</el-button>
                <el-button type="danger" size="small" @click="clickDeleteBtn(scope.row)" v-prevent-click v-if="isDelete">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="pad_15 text_right">
            <el-pagination background v-if="paginations.total > 0" @current-change="handleCurrentChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
          </div>
        </div>
        <!--新增、编辑弹出框-->
        <el-dialog title="支出记账" :visible.sync="dialogVisible" width="800px" destroy-on-close append-to-body>
          <div>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" size="small">
              <el-row>
                <el-col :span="12">
                  <el-form-item label="支出类型" prop="SpendingCategoryID">
                    <el-select clearable v-model="ruleForm.SpendingCategoryID" placeholder="请选择类别" filterable class="width_220">
                      <el-option :label="item.Name" :value="item.ID" v-for="(item, index) in BillTypeList" :key="index"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="门店" prop="EntityID">
                    <el-select class="width_220" clearable filterable v-model="ruleForm.EntityID" placeholder="请选择门店">
                      <el-option :label="item.Name" :value="item.ID" v-for="(item, index) in entityList" :key="index"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="付款日期" prop="SpendingDate">
                    <el-date-picker class="width_220" v-model="ruleForm.SpendingDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="付款人" prop="Payer">
                    <el-input v-model="ruleForm.Payer" placeholder="请输入付款人" class="width_220"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="支付方式" prop="PaymentsID">
                    <el-select clearable v-model="ruleForm.PaymentsID" placeholder="请选择支付方式" filterable class="width_220">
                      <el-option v-for="(item, index) in PaymentsList" :label="item.Name" :value="item.ID" :key="index"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="金额" prop="Amount">
                    <el-input v-model="ruleForm.Amount" placeholder="请输入支付金额" type="number" class="width_220"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="23">
                  <el-form-item label="支出内容" prop="Content">
                    <el-input type="textarea" :rows="4" placeholder="请输入支出内容" v-model="ruleForm.Content"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false" size="small" v-prevent-click>取 消</el-button>
            <el-button type="primary" size="small" v-prevent-click @click="submitForm('ruleForm')" :loading="modalLoading">确 定</el-button>
          </span>
        </el-dialog>
      </el-tab-pane>
      <el-tab-pane label="额外收入管理" name="1">
        <div class="nav_header">
          <el-row>
            <el-col :span="22">
              <el-form :inline="true" size="small" @keyup.enter.native="handleSearch">
                <el-form-item label="收入来源">
                  <el-input style="width:150px;" @clear="handleSearch" v-model="IncomeName" placeholder="请输入收入来源" clearable></el-input>
                </el-form-item>
                <!-- 选择门店 -->
                <el-form-item label="门店" v-if="entityList.length>1">
                  <el-select filterable style="width:150px;" clearable v-model="EntityID" placeholder="请选择门店" @change="handleSearch" @clear="handleSearch">
                    <el-option :label="item.Name" :value="item.ID" v-for="(item, index) in entityList" :key="index"></el-option>
                  </el-select>
                </el-form-item>
                <!-- 收入类型 -->
                <el-form-item label="收入类型">
                  <el-select style="width:150px;" clearable v-model="IncomeType" placeholder="请选择类别" @change="handleSearch" @clear="handleSearch">
                    <el-option :label="item.Name" :value="item.ID" v-for="(item, index) in IncomeList" :key="index"></el-option>
                  </el-select>
                  <!-- 筛选时间 -->
                </el-form-item>
                <el-form-item label="筛选时间">
                  <el-date-picker v-model="dateTime" range-separator="至" type="daterange" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" size="small" @click="handleSearch" v-prevent-click>搜索</el-button>
                </el-form-item>
              </el-form>
            </el-col>
            <el-col :span="2" class="text_right">
              <el-button type="primary" size="small" @click="showAddDialog" v-prevent-click>新增收入</el-button>
            </el-col>
          </el-row>
        </div>
        <div class="martp_10">
          <el-table :data="IncomeTableData" size="small" tooltip-effect="light">
            <el-table-column prop="CategoryName" label="收入类型"></el-table-column>
            <el-table-column label="收款方式/金额">
              <template slot-scope="scope">
                <div>
                  <div>{{scope.row.PaymentsName}}</div>
                  <div>{{scope.row.Amount}}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="收入来源/时间">
              <template slot-scope="scope">
                <div>
                  <div>{{scope.row.IncomeSource}}</div>
                  <div>{{scope.row.InComeDate}}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="记录人/时间">
              <template slot-scope="scope">
                <div>
                  <div>{{scope.row.EmpName}}</div>
                  <div>{{scope.row.CreatedOn}}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="Content" label="收入内容" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作" width="145px">
              <template slot-scope="scope">
                <el-button type="primary" size="small" @click="clickEditBtn(scope.row)" v-prevent-click>编辑</el-button>
                <el-button type="danger" size="small" v-if="isDelete" @click="clickDeleteBtn(scope.row)" v-prevent-click>删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="pad_15 text_right">
            <el-pagination background v-if="paginations.total > 0" @current-change="handleCurrentChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
          </div>
        </div>
        <!--新增、编辑弹出框-->
        <el-dialog title="收入记账" :visible.sync="dialogVisible1" width="800px" destroy-on-close append-to-body>
          <div>
            <el-form :model="IncomeForm" :rules="Incomerules" ref="IncomeForm" label-width="120px" size="small">
              <el-row>
                <el-col :span="12">
                  <el-form-item label="收入类型" prop="IncomeCategoryID">
                    <el-select clearable v-model="IncomeForm.IncomeCategoryID" placeholder="请选择类别" filterable class="width_220">
                      <el-option :label="item.Name" :value="item.ID" v-for="(item, index) in IncomeList" :key="index"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="门店" prop="EntityID">
                    <el-select clearable filterable v-model="IncomeForm.EntityID" placeholder="请选择门店" class="width_220">
                      <el-option :label="item.Name" :value="item.ID" v-for="(item, index) in entityList" :key="index"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="收款日期" prop="InComeDate">
                    <el-date-picker v-model="IncomeForm.InComeDate" type="date" value-format="yyyy-MM-dd" class="width_220" placeholder="选择日期"></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="收入来源" prop="IncomeSource">
                    <el-input v-model="IncomeForm.IncomeSource" placeholder="请输入收入来源" class="width_220"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="收款方式" prop="PaymentsID">
                    <el-select clearable v-model="IncomeForm.PaymentsID" placeholder="请选择收款方式" filterable class="width_220">
                      <el-option v-for="(item, index) in PaymentsList" :label="item.Name" :value="item.ID" :key="index"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="金额" prop="Amount">
                    <el-input v-model="IncomeForm.Amount" type="number" placeholder="请输入收款金额" class="width_220"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="23">
                  <el-form-item label="收入内容" prop="Content">
                    <el-input type="textarea" :rows="4" placeholder="请输入收入内容" v-model="IncomeForm.Content"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible1 = false" size="small" v-prevent-click>取 消</el-button>
            <el-button type="primary" size="small" v-prevent-click @click="submitForm('IncomeForm')" :loading="modalLoading">确 定</el-button>
          </span>
        </el-dialog>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import SpendAPI from "@/api/iBeauty/Basic/incomeAndSpending";
import permission from "@/components/js/permission";
export default {
  name: "incomeAndSpending",
  data() {
    return {
      isDelete: false, //删除权限
      dateTime: "",
      BillType: "", //支出类型
      IncomeType: "", // 收入类型
      BillTypeList: [], //支出类型列表
      IncomeList: [], //收入类型列表
      PaymentsList: [],
      EmpStores: "",
      activeName: "0",
      loading: false,
      modalLoading: false,
      payName: "", // 付款人
      IncomeName: "", //收款人
      productList: [], // 产品列表
      whichDialog: 0, // 判断弹出框类型
      EntityName: "", // 组织单位搜索框数据
      productTableData: [], // 表格数据
      IncomeTableData: [], //收入列表
      ProductID: "", // 当前产品ID
      EntityID: "", // 当前组织单位ID
      dialogVisible: false, //
      dialogVisible1: false, //
      entityList: [], // 组织单位数据
      ruleForm: {
        //支出弹窗数据
        SpendingDate: "", // 付款日期
        SpendingCategoryID: "", // 支出类型
        PaymentsID: "", //支付方式
        Amount: "", // 调整价
        EntityID: null, // 门店
        Payer: "", // 付款人
        Content: "", // 内容
      }, // 新增、编辑表单数据
      IncomeForm: {
        //收入弹窗数据
        InComeDate: "",
        IncomeCategoryID: "",
        PaymentsID: "",
        Amount: "",
        EntityID: null,
        IncomeSource: "",
        Content: "",
      },
      rules: {
        SpendingCategoryID: [
          { required: true, message: "请选择支出类型", trigger: "change" },
        ],
        Amount: [
          { required: true, message: "请填写支付金额", trigger: "blur" },
        ],
        EntityID: [{ required: true, message: "请选择门店", trigger: "blur" }],
        SpendingDate: [
          { required: true, message: "请选择付款时间", trigger: "blur" },
        ],
        PaymentsID: [
          { required: true, message: "请选择支付方式", trigger: "change" },
        ],
        Payer: [{ required: true, message: "请填写付款人", trigger: "blur" }],
      },
      Incomerules: {
        SpendingCategoryID: [
          { required: true, message: "请选择收入类型", trigger: "change" },
        ],
        Amount: [
          { required: true, message: "请填写收入金额", trigger: "blur" },
        ],
        EntityID: [{ required: true, message: "请选择门店", trigger: "blur" }],
        SpendingDate: [
          { required: true, message: "请选择收款时间", trigger: "blur" },
        ],
        PaymentsID: [
          { required: true, message: "请选择收款方式", trigger: "change" },
        ],
        Payer: [{ required: true, message: "请填写收款人", trigger: "blur" }],
      },
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      }, //需要给分页组件传的信息
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.isDelete = permission.permission(
        to.meta.Permission,
        "iBeauty-AdditionalPayments-IncomeAndSpending-Delete"
      );
    });
  },
  methods: {
    handleClick() {
      this.paginations.page = 1;
      if (this.activeName == "0") {
        this.getProductPriceAdjustment();
      } else {
        this.getIncomeList();
      }
    },
    // 搜索事件
    handleSearch() {
      var that = this;
      that.paginations.page = 1;
      if (that.activeName == "0") {
        that.getProductPriceAdjustment();
      } else {
        that.getIncomeList();
      }
    },
    // 上下分页
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      if (that.activeName == "0") {
        that.getProductPriceAdjustment();
      } else {
        that.getIncomeList();
      }
    },
    // 所属单位
    entityData: function () {
      var that = this;
      SpendAPI.getEntityAll()
        .then((res) => {
          if (res.StateCode == 200) {
            that.entityList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 支出类型
    SpendType() {
      var that = this;
      SpendAPI.SpendingCategory().then((res) => {
        if (res.StateCode == 200) {
          that.BillTypeList = res.Data;
        }
      });
    },
    //收入类型
    Income() {
      var that = this;
      SpendAPI.IncomeCategory().then((res) => {
        if (res.StateCode == 200) {
          that.IncomeList = res.Data;
        }
      });
    },
    // 支付方式
    Payments() {
      var that = this;
      SpendAPI.Payments().then((res) => {
        if (res.StateCode == 200) {
          that.PaymentsList = res.Data;
        }
      });
    },
    // 支出列表
    getProductPriceAdjustment: function () {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.paginations.page,
        Payer: that.payName,
        SpendingCategoryID: that.BillType,
        EntityID: that.EmpStores,
        StartDate: that.dateTime ? that.dateTime[0] : "",
        EndDate: that.dateTime ? that.dateTime[1] : "",
      };
      SpendAPI.SpendingList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            if (res.List.length == 0 && that.paginations.page > 1) {
              that.paginations.page = that.paginations.page - 1;
              that.getProductPriceAdjustment();
            }
            that.productTableData = res.List;
            that.paginations.total = res.Total;
            that.paginations.page_size = res.PageSize;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 收入列表
    getIncomeList() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.paginations.page,
        IncomeSource: that.IncomeName,
        IncomeCategoryID: that.IncomeType,
        EntityID: that.EntityID,
        StartDate: that.dateTime ? that.dateTime[0] : "",
        EndDate: that.dateTime ? that.dateTime[1] : "",
      };
      SpendAPI.IncomeList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            if (res.List.length == 0 && that.paginations.page > 1) {
              that.paginations.page = that.paginations.page - 1;
              that.getIncomeList();
            }
            that.IncomeTableData = res.List;
            that.paginations.total = res.Total;
            that.paginations.page_size = res.PageSize;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 新增按钮点击事件
    showAddDialog() {
      var that = this;
      that.whichDialog = 0;
      if (that.activeName == "0") {
        that.ruleForm = {
          SpendingCategoryID: null,
          EntityID: that.EntityID,
          SpendingDate: null,
          PaymentsID: null,
          Amount: null,
          Payer: null,
          Content: null,
        };
        that.dialogVisible = true;
      } else {
        that.IncomeForm = {
          IncomeCategoryID: null,
          EntityID: that.EntityID,
          InComeDate: null,
          PaymentsID: null,
          Amount: null,
          IncomeSource: null,
          Content: null,
        };
        that.dialogVisible1 = true;
      }
    },
    // 编辑按钮点击事件
    clickEditBtn(row) {
      var that = this;
      that.whichDialog = 1;
      if (that.activeName == "0") {
        that.ruleForm = Object.assign({}, row);
        that.dialogVisible = true;
      } else {
        that.IncomeForm = Object.assign({}, row);
        that.dialogVisible1 = true;
      }
    },
    // 提交编辑、新增表单
    submitForm(formName) {
      var that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          if (that.whichDialog == 0) {
            that.createProductPriceAdjustment();
          } else {
            that.updateProductPriceAdjustment();
          }
        }
      });
    },
    // 新增
    createProductPriceAdjustment: function () {
      var that = this;
      that.modalLoading = true;
      if (that.activeName == "0") {
        var params = {
          SpendingCategoryID: that.ruleForm.SpendingCategoryID,
          EntityID: that.ruleForm.EntityID,
          SpendingDate: that.ruleForm.SpendingDate,
          PaymentsID: that.ruleForm.PaymentsID,
          Amount: that.ruleForm.Amount,
          Payer: that.ruleForm.Payer,
          Content: that.ruleForm.Content,
        };
        SpendAPI.create(params)
          .then((res) => {
            if (res.StateCode == 200) {
              that.dialogVisible = false;
              that.$message.success("成功");
              that.getProductPriceAdjustment();
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          })
          .finally(function () {
            that.modalLoading = false;
          });
      } else {
        var params = {
          IncomeCategoryID: that.IncomeForm.IncomeCategoryID,
          EntityID: that.IncomeForm.EntityID,
          InComeDate: that.IncomeForm.InComeDate,
          PaymentsID: that.IncomeForm.PaymentsID,
          Amount: that.IncomeForm.Amount,
          IncomeSource: that.IncomeForm.IncomeSource,
          Content: that.IncomeForm.Content,
        };
        SpendAPI.Incomecreate(params)
          .then((res) => {
            if (res.StateCode == 200) {
              that.dialogVisible1 = false;
              that.$message.success("成功");
              that.getIncomeList();
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          })
          .finally(function () {
            that.modalLoading = false;
          });
      }
    },
    // 更新支出
    updateProductPriceAdjustment: function () {
      var that = this;
      that.modalLoading = true;
      if (that.activeName == "0") {
        var params = {
          ID: that.ruleForm.ID,
          SpendingCategoryID: that.ruleForm.SpendingCategoryID,
          EntityID: that.ruleForm.EntityID,
          SpendingDate: that.ruleForm.SpendingDate,
          PaymentsID: that.ruleForm.PaymentsID,
          Amount: that.ruleForm.Amount,
          Payer: that.ruleForm.Payer,
          Content: that.ruleForm.Content,
        };
        SpendAPI.update(params)
          .then((res) => {
            if (res.StateCode == 200) {
              that.dialogVisible = false;
              that.$message.success("成功");
              that.getProductPriceAdjustment();
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          })
          .finally(function () {
            that.modalLoading = false;
          });
      } else {
        var params = {
          ID: that.IncomeForm.ID,
          IncomeCategoryID: that.IncomeForm.IncomeCategoryID,
          EntityID: that.IncomeForm.EntityID,
          InComeDate: that.IncomeForm.InComeDate,
          PaymentsID: that.IncomeForm.PaymentsID,
          Amount: that.IncomeForm.Amount,
          IncomeSource: that.IncomeForm.IncomeSource,
          Content: that.IncomeForm.Content,
        };
        SpendAPI.IncomeUpdate(params)
          .then((res) => {
            if (res.StateCode == 200) {
              that.dialogVisible1 = false;
              that.$message.success("成功");
              that.getIncomeList();
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          })
          .finally(function () {
            that.modalLoading = false;
          });
      }
    },
    // 删除按钮点击事件
    clickDeleteBtn(row) {
      var that = this;
      that
        .$confirm("此操作将永久删除该记录, 是否继续?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that.loading = true;
          var params = {
            ID: row.ID,
          };
          if (that.activeName == "0") {
            SpendAPI.delete(params)
              .then((res) => {
                if (res.StateCode == 200) {
                  that.$message.success("成功");
                  that.getProductPriceAdjustment();
                } else {
                  that.$message.error({
                    message: res.Message,
                    duration: 2000,
                  });
                }
              })
              .finally(function () {
                that.loading = false;
              });
          } else {
            SpendAPI.IncomeDelete(params)
              .then((res) => {
                if (res.StateCode == 200) {
                  that.$message.success("成功");
                  that.getIncomeList();
                } else {
                  that.$message.error({
                    message: res.Message,
                    duration: 2000,
                  });
                }
              })
              .finally(function () {
                that.loading = false;
              });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  mounted() {
    var that = this;
    that.entityData(); //门店列表
    that.SpendType(); //支出类型
    that.Income(); //收入类型
    that.getProductPriceAdjustment(); //支出列表
    that.getIncomeList(); //收入列表
    that.Payments(); //收付款方式
    // that.EntityID = JSON.parse(localStorage.getItem("access-user")).EntityID.toString() ||"";
    // that.EmpStores =  JSON.parse(localStorage.getItem("access-user")).EntityID.toString() ||"";
    that.isDelete = permission.permission(
      that.$route.meta.Permission,
      "iBeauty-AdditionalPayments-IncomeAndSpending-Delete"
    );
  },
};
</script>

<style scoped lang="scss">
</style>